// bazı gönderiler yalnızca tek bir dilde eklendikleri halde kendilerine
// dair iki farklı kayıt bulunabiliyor. özellikle yalnızca Türkçesi bulunan
// blog yazılarında bu sorunla karşılaşıyoruz. içerik, bütün alanları
// kopyalanmış bir şekilde ingilizce olarak da karşımıza çıkıyor.

// bu sorunun önüne geçmek için _allSlugLocales alanına bakmak gerekiyor.
// eğer bu alan belirtilen dil için bir değer içeriyorsa içerik çevrilmiş
// "olabilir". Evet, _allSlugLocales alanında bir değer olsa dahi
// içerik çevrilmiş olmayabilir. Şimdilik yalnızca bu düzeyde sorunu çözebiliyoruz.

module.exports = function isTranslated(node) {
  const { locale, _allSlugLocales } = node

  return _allSlugLocales.some(slugLocale => slugLocale.locale === locale)
}

module.exports.default = module.exports
