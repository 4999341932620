import React, { useRef, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import map from 'lodash/map'
import get from 'lodash/get'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import AuthorCard from '@c/author-card'
import ResourceBlogPost from '@c/resource-blog-post'
import Icon from '@c/icon'
import Book from '@c/book'
import ResourceBook from '@c/resource-book'
import ResourceVideo from '@c/resource-video'
import Video from '@c/video'
import Slider from '@c/slider-flickity'
import makeSlug from '@u/make-slug'
import isTranslated from '@u/is-translated'
import ResourcesPage from '../../models/ResourcesPage'
import FlexibleLink from '@c/common/FlexibleLink'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'
import QuickSearch from '@c/quick-search'
import quickSearchBuilder, {
  QuickSearchMapping
} from '@c/quick-search/quick-search-builder'

/**
 * Makalelerden 50 tanesini sorgulayıp içlerinden gerçekten çevrilmiş olanları ayıklayıp
 * gösteriyoruz. Çeviri sorunu ile ilgili detaylı bilgiye README.md dosyasından ulaşabilirsiniz.
 *
 */

const ResourcePage = ({ data }) => {
  const videoThumb = (video) => video?.thumb || video?.localVideoThumbnail
  const { page, pageSettings } = data
  const {
    _allResourcesSlugLocales,
    resourcesSlug,
    resourcesArticleSlug,
    resourcesGuideSlug,
    resourcesBookSlug,
    resourcesVideoSlug,
    categorySlug
  } = pageSettings
  const model = new ResourcesPage(page, {
    locale: page.locale,
    slugLocales: _allResourcesSlugLocales
  })
  const parentPages = []
  const { resourcesTitle: title } = pageSettings
  const articles = map(data.articles.edges, 'node').filter(isTranslated)
  const guides = map(data.guides.edges, 'node').filter(isTranslated)
  const books = map(data.books.edges, 'node').filter(isTranslated)
  const videos = map(data.videos.edges, 'node').filter(isTranslated)
  const allArticles = map(data.allArticles.edges, 'node').filter(isTranslated)
  const allGuides = map(data.allGuides.edges, 'node').filter(isTranslated)
  const allBooks = map(data.allBooks.edges, 'node').filter(isTranslated)
  const allVideos = map(data.allVideos.edges, 'node').filter(isTranslated)
  const searchMappings = quickSearchBuilder()
    .addCriteria(
      new QuickSearchMapping(allArticles, (item) =>
        makeSlug.generic(
          page.locale,
          resourcesSlug,
          resourcesArticleSlug,
          item?.slug
        )
      )
    )
    .addCriteria(
      new QuickSearchMapping(allGuides, (item) =>
        makeSlug.generic(
          page.locale,
          resourcesSlug,
          resourcesGuideSlug,
          item?.slug
        )
      )
    )
    .addCriteria(
      new QuickSearchMapping(allBooks, (item) =>
        makeSlug.generic(
          page.locale,
          resourcesSlug,
          resourcesBookSlug,
          item?.slug
        )
      )
    )
    .addCriteria(
      new QuickSearchMapping(allVideos, (item) =>
        makeSlug.generic(
          page.locale,
          resourcesSlug,
          resourcesVideoSlug,
          item?.slug
        )
      )
    )
    .build()
  const sliderBookRef = useRef()
  const sliderArrowRef = useRef()
  const filteredArticles = articles
  const filteredGuides = guides
  const filteredBooks = books
  const filteredVideos = videos
  const featuredVideo = filteredVideos.shift()
  const specialLinks = page.pageLinks.map((link) => new DatoCmsLink(link))

  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderBookRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])
  return (
    <Layout locale={page.locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <Breadcrumb parentPages={parentPages} currentPage={title} />
          </div>
          <div className="col-12 col-lg-auto">
            <QuickSearch mappings={searchMappings} />
          </div>
        </div>
      </div>

      {/* hero */}
      <div className="resource-hero ta-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <PageHeader title={page.title} />
              <p className="mt-24">{page.description}</p>
            </div>
          </div>
          <figure className="mt-40">
            <img alt="hero" src="/assets/resources/hero-image.svg" />
          </figure>
        </div>
      </div>

      {/* blog */}
      <div className="mt-60 mt-lg-120">
        <div className="container">
          <div className="row ta-center">
            <div className="col-12 col-6">
              <PageHeader
                topTitle={page.articlesTitleSmall}
                title={page.articlesTitleBig}
                type="h3"
              />
            </div>
          </div>
          {/* list */}
          <div className="row mt-40">
            {filteredArticles.map((article) => (
              <div key={article.id} className="col-12 col-lg-6">
                <ResourceBlogPost
                  categoryBase={makeSlug.generic(
                    page.locale,
                    resourcesSlug,
                    resourcesArticleSlug,
                    categorySlug
                  )}
                  categories={article.category ? [article.category] : []}
                  title={article.title}
                  cover={article.image}
                  authorNickname={article.author?.nickname}
                  authorName={`${article.author?.firstName} ${article.author?.lastName}`}
                  authorPhoto={article.author?.profileImage}
                  url={makeSlug.generic(
                    page.locale,
                    resourcesSlug,
                    resourcesArticleSlug,
                    article.slug
                  )}
                  locale={page.locale}
                >
                  {article.descriptionSmall}
                </ResourceBlogPost>
              </div>
            ))}
          </div>
          {/* all-link */}
          <div className="mt-32">
            <Link
              className="fs-small link-hover-red"
              to={makeSlug.generic(
                page.locale,
                resourcesSlug,
                resourcesArticleSlug
              )}
            >
              {page.allArticlesLinkText}
              <Icon className="ml-8" />
            </Link>
          </div>
        </div>
      </div>

      {/* guides */}
      <div className="mt-60 mt-lg-120">
        <div className="container">
          <div className="row ta-center">
            <div className="col-12 col-6">
              <PageHeader
                topTitle={page.guidesTitleSmall}
                title={page.guidesTitleBig}
                type="h3"
              />
            </div>
          </div>
          {/* list */}
          <div className="row mt-40">
            {filteredGuides.map((article) => (
              <div key={article.id} className="col-12 col-lg-6">
                <ResourceBlogPost
                  categoryBase={makeSlug.generic(
                    page.locale,
                    resourcesSlug,
                    resourcesGuideSlug,
                    categorySlug
                  )}
                  categories={article.category ? [article.category] : []}
                  title={article.title}
                  cover={article.image}
                  authorNickname={article.author?.nickname}
                  authorName={`${article.author.firstName} ${article.author.lastName}`}
                  authorPhoto={article.author?.profileImage}
                  url={makeSlug.generic(
                    page.locale,
                    resourcesSlug,
                    resourcesGuideSlug,
                    article.slug
                  )}
                  locale={page.locale}
                >
                  {article.descriptionSmall}
                </ResourceBlogPost>
              </div>
            ))}
          </div>
          {/* all-link */}
          <div className="mt-32">
            <Link
              className="fs-small link-hover-red"
              to={makeSlug.generic(
                page.locale,
                resourcesSlug,
                resourcesGuideSlug
              )}
            >
              {page.allGuidesLinkText}
              <Icon className="ml-8" />
            </Link>
          </div>
        </div>
      </div>

      {/* book */}
      <div className="mt-60 mt-lg-80">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10">
              <PageHeader
                topTitle={page.booksTitleSmall}
                title={page.booksTitleBig}
                type="h3"
              />
            </div>
            {/* all-link */}
            <div className=" mt-12 col-lg-2">
              <Link
                className="fs-small link-hover-red"
                to={makeSlug.generic(
                  page.locale,
                  resourcesSlug,
                  resourcesBookSlug
                )}
              >
                {page.allBooksLinkText}
                <Icon className="ml-8" />
              </Link>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            {/* TODO: çevrilecek */}
            <p className="col-12 col-lg-9 mt-16 mt-lg-8">
              {page.booksDescription}
            </p>
            {/* arrow */}
            <div
              className="col-12 col-lg-3 d-flex align-items-center my-60 my-lg-0 ml-32 ml-lg-0"
              style={{ minWidth: 120 + 'px', maxWidth: 145 + 'px' }}
            >
              <Slider
                className="col-10"
                ref={sliderArrowRef}
                options={{
                  prevNextButtons: true,
                  draggable: true
                }}
              >
                {books.map((book) => (
                  <i key={book.id}></i>
                ))}
              </Slider>
              {/* toplam item sayısı devre dısı bırakıldı */}
              {/* <p className="ml-16 col-2">{books.length}item</p> */}
            </div>
          </div>
          {/* list */}
          <div className="row">
            <div className="col-12">
              <Slider
                ref={sliderBookRef}
                options={{
                  prevNextButtons: false,
                  draggable: false
                }}
              >
                {filteredBooks.map((book) => (
                  <div key={book.id} className="col-12 col-lg-4 mb-40 mb-lg-0">
                    <ResourceBook
                      url={makeSlug.generic(
                        page.locale,
                        resourcesSlug,
                        resourcesBookSlug,
                        book.slug
                      )}
                    >
                      <Book
                        photo={get(book, 'coverImage')}
                        alt={get(book, 'coverImage.alt') || ''}
                        title={book.coverTitleFirst}
                      />
                      <ResourceBook.Title>{book.title}</ResourceBook.Title>
                      <ResourceBook.Footer>
                        {get(book, 'author.firstName') &&
                          get(book, 'author.lastName') && (
                          <AuthorCard
                            photo={get(book, 'author.profileImage')}
                            alt={get(book, 'author.profileImage.alt') || ''}
                            name={`${get(book, 'author.firstName')} ${get(
                              book,
                              'author.lastName'
                            )}`}
                            nickname={get(book, 'author.nickname')}
                            categoryBase={makeSlug.generic(
                              page.locale,
                              resourcesSlug,
                              resourcesBookSlug,
                              categorySlug
                            )}
                            categories={book.categories}
                          />
                        )}
                      </ResourceBook.Footer>
                    </ResourceBook>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* video */}
      <div className="mt-60 mt-lg-80">
        <div className="container d-flex flex-wrap">
          {/* list */}
          <div className="col-12 col-lg-6  pl-0 ml-0">
            <div className="row">
              <div className="col-12 col-6">
                <PageHeader
                  topTitle={page.videosTitleSmall}
                  title={page.videosTitleBig}
                  type="h3"
                />
              </div>
              <p className="ml-16  mt-32">{page.videoSectionDescription}</p>
            </div>
            <div className="row mt-40 d-flex align-items-start">
              {filteredVideos.map((video) => (
                <div key={video.id} className="col-6 mb-40 mb-lg-0">
                  <ResourceVideo
                    url={makeSlug.generic(
                      page.locale,
                      resourcesSlug,
                      resourcesVideoSlug,
                      video.slug
                    )}
                  >
                    <Video
                      photo={videoThumb(video)}
                      totalTime={video.duration}
                    />
                    <ResourceVideo.Title>{video.title}</ResourceVideo.Title>
                    <ResourceVideo.Footer>
                      <AuthorCard
                        name={`${get(video, 'author.firstName')} ${get(
                          video,
                          'author.lastName'
                        )}`}
                        nickname={get(video, 'author.nickname')}
                        categoryBase={makeSlug.generic(
                          page.locale,
                          resourcesSlug,
                          resourcesVideoSlug,
                          categorySlug
                        )}
                        categories={video.categories}
                        locale={page.locale}
                      />
                    </ResourceVideo.Footer>
                  </ResourceVideo>
                </div>
              ))}
            </div>
          </div>

          <div className="col-12 col-lg-6">
            {featuredVideo && (
              <div key={featuredVideo.id}>
                <ResourceVideo
                  url={makeSlug.generic(
                    page.locale,
                    resourcesSlug,
                    resourcesVideoSlug,
                    featuredVideo.slug
                  )}
                >
                  {/* Temp static img */}
                  {/* <img className="" src="../../../assets/resources/video-cover-1.jpg" /> */}
                  <Video
                    photo={videoThumb(featuredVideo)}
                    totalTime={featuredVideo.duration}
                  />
                  <ResourceVideo.Title>
                    {featuredVideo.title}
                  </ResourceVideo.Title>
                  <ResourceVideo.Footer>
                    <AuthorCard
                      photo={get(featuredVideo, 'author.profileImage')}
                      alt={get(featuredVideo, 'author.profileImage.alt') || ''}
                      name={`${get(featuredVideo, 'author.firstName')} ${get(
                        featuredVideo,
                        'author.lastName'
                      )}`}
                      nickname={get(featuredVideo, 'author.nickname')}
                      categoryBase={makeSlug.generic(
                        page.locale,
                        resourcesSlug,
                        resourcesVideoSlug,
                        categorySlug
                      )}
                      categories={featuredVideo.categories}
                      locale={page.locale}
                    />
                  </ResourceVideo.Footer>
                </ResourceVideo>
              </div>
            )}
          </div>

          {/* all-link */}
          <div className="mt-32">
            <Link
              className="fs-small link-hover-red"
              to={makeSlug.generic(
                page.locale,
                resourcesSlug,
                resourcesVideoSlug
              )}
            >
              {page.allVideosLinkText}
              <Icon className="ml-8" />
            </Link>
          </div>
        </div>
      </div>
      {/* special-content */}
      <div className="container mt-60 mt-lg-80">
        <div className="row ta-center">
          <div className="col-12 col-6">
            <PageHeader
              topTitle={page.specialTitleSmall}
              title={page.specialTitleBig}
              type="h3"
            />
          </div>
        </div>
      </div>
      <div className="">
        <div className="container d-flex flex-wrap justify-content-center">
          {specialLinks.map((link) => (
            <div
              key={link.getId()}
              className="col-12 col-lg-4 d-flex align-items-stretch my-32"
            >
              <div className="col-12 border-all px-0 align-items-stretch">
                <FlexibleLink
                  className="d-flex flex-column align-items-center"
                  title={
                    <>
                      {link.hasImage() && (
                        <GatsbyImage
                          // style={{ ...imgHeight }} // önceki stiller
                          style={{ height: '10em', width: '100%' }}
                          className="px-4 pt-16 pb-0"
                          {...link.getImgProps()}
                        />
                      )}
                      <p className="fs-large ta-center py-40 px-80">
                        {link.getTitle()}
                      </p>
                    </>
                  }
                  url={link.getUrl()}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ResourcePage

export const query = graphql`
  query ResourcesPageQuery($locale: String) {
    page: datoCmsResourcesPage(locale: { eq: $locale }) {
      title
      locale
      description

      articlesTitleSmall
      articlesTitleBig
      allArticlesLinkText

      guidesTitleSmall
      guidesTitleBig
      allGuidesLinkText

      booksTitleSmall
      booksTitleBig
      booksDescription
      allBooksLinkText

      videosTitleSmall
      videosTitleBig
      videoSectionDescription
      allVideosLinkText

      metaTags {
        ...MetaTags
      }

      specialTitleSmall
      specialTitleBig
      pageLinks {
        id
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      locale
      resourcesSlug
      resourcesTitle
      resourcesArticleSlug
      resourcesGuideSlug
      resourcesBookSlug
      resourcesVideoSlug
      categorySlug
    }

    articles: allDatoCmsArticle(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
      sort: { fields: [meta___firstPublishedAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          id
          locale
          title
          slug
          descriptionSmall
          category {
            title
            slug
          }
          image {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          author {
            firstName
            lastName
            nickname
            profileImage {
              alt
              asset {
                localImage {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
    allArticles: allDatoCmsArticle(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }

    #Guides
    guides: allDatoCmsGuide(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
      sort: { fields: [meta___firstPublishedAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          id
          locale
          title
          slug
          descriptionSmall
          category {
            title
            slug
          }
          image {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          author {
            firstName
            lastName
            nickname
            profileImage {
              alt
              asset {
                localImage {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }

    allGuides: allDatoCmsGuide(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }

    books: allDatoCmsBook(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
      sort: { fields: [meta___createdAt], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          id
          locale
          title
          slug
          coverTitleFirst
          coverTitleSecond
          coverDescription
          categories {
            title
            slug
          }
          coverImage {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }

          author {
            firstName
            lastName
            nickname
            profileImage {
              alt
              asset {
                localImage {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 30)
                  }
                }
              }
            }
          }
        }
      }
    }

    allBooks: allDatoCmsBook(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }

    videos: allDatoCmsVideo(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
      sort: { fields: [createdAtCustom], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          id
          locale
          slug
          title
          shortDescription
          duration
          categories {
            title
            slug
          }
          thumb {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          localVideoThumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          video {
            title
            url
            thumbnailUrl
          }
          author {
            firstName
            lastName
            nickname
            profileImage {
              alt
              asset {
                localImage {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 30)
                  }
                }
              }
            }
          }
        }
      }
    }
    allVideos: allDatoCmsVideo(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }

    teamPage: datoCmsTeamPage(locale: { eq: $locale }) {
      slug
    }
  }
`
